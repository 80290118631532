import React from "react";
import "./App.scss";

import Header from "./components/Header";
import Menu from "./pages/Menu";
import Catering from "./pages/Catering";
import OurStory from "./pages/OurStory";
import Home from "./pages/Home";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="app-body">
          <Switch>
            <Route exact path="/" component={Menu} />
            <Route exact path="/menu" component={Menu} />
            <Route exact path="/catering" component={Catering} />
            <Route exact path="/our-story" component={OurStory} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
