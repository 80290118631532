import React, { useEffect, useRef, useState } from "react";
import {
  menu,
  coldApps,
  hotApps,
  buffetSalads,
  buffetVeggies,
  buffetProtein,
  buffetStarches,
  italianPasta,
  italianSauces,
  italianProtein,
  italianSpecialty,
  mexEnchiladas,
  desserts,
  tacoProteins,
  mexTacos,
  mexFajitas,
} from "../data/menu.js";

import * as menuFile from "./menu.pdf";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Menu() {
  const [selected, setSelected] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeight, setPdfHeight] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const h = document.getElementById("pdf").offsetHeight - 24;
    setPdfHeight(h);
  }, []);

  return (
    <div className="menu-page">
      <div className="menu-container">
        <ul uk-tab="true">
          <li class="uk-active">
            <a
              className="menu-tab-link"
              href="#"
              onClick={() => {
                setSelected(1);
              }}
            >
              Appetizers
            </a>
          </li>
          <li>
            <a
              href="#"
              className="menu-tab-link"
              onClick={() => {
                setSelected(2);
              }}
            >
              Buffet
            </a>
          </li>
          <li>
            <a
              href="#"
              className="menu-tab-link"
              onClick={() => {
                setSelected(3);
              }}
            >
              Italian Buffet
            </a>
          </li>
          <li>
            <a
              href="#"
              className="menu-tab-link"
              onClick={() => {
                setSelected(4);
              }}
            >
              Mexican Buffet
            </a>
          </li>
          <li>
            <a
              href="#"
              className="menu-tab-link"
              onClick={() => {
                setSelected(5);
              }}
            >
              Desserts
            </a>
          </li>
        </ul>

        <a href={menuFile} target="_blank">
          <div
            className="pdf uk-flex uk-flex-center"
            id="pdf"
            uk-tooltip="title: Open Menu as PDF; pos: center;"
          >
            <Document file={menuFile} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={selected} height={pdfHeight} />
            </Document>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Menu;
