import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Hamburger from "./Hamburger";

const Header = ({ history }) => {
  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "MENU",
  });
  // State of our button
  const [disabled, setDisabled] = useState(false);

  //Use Effect
  useEffect(() => {
    //Listening for page changes.
    history.listen(() => {
      setState({ clicked: false, menuName: "MENU" });
    });
  }, [history]);

  useEffect(() => {
    console.log("button " + disabled);
  }, [disabled]);

  // Toggle menu
  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "CLOSE",
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "MENU",
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "CLOSE",
      });
    }
  };

  //Determine if out menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <header>
      <div className="container">
        <div className="inner-header" id="Header">
          <div className="logo">
            <Link to="/">
              <img src="./images/textlesslogo.svg"></img>
            </Link>
          </div>
          <div className="menu">
            <button
              className={`button ${disabled && "disabled"}`}
              disabled={disabled}
              onClick={handleMenu}
            >
              {state.menuName === "MENU" ? (
                <svg
                  viewBox="0 0 100 80"
                  width="40"
                  height="40"
                  fill="currentColor"
                >
                  <rect width="100" height="10"></rect>
                  <rect y="30" width="100" height="10"></rect>
                  <rect y="60" width="100" height="10"></rect>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <Hamburger state={state} disabled={disabled} />
    </header>
  );
};

export default withRouter(Header);
